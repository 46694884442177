@import '../assets/scss/colors';
@import '../assets/scss/fonts';
@import '../assets/scss/mixins';
@import '../assets/scss/extended-classes';
@import '../assets/scss/break-points';
@import '../assets/scss/notifications-panel';

.mobile-menu-icon {
  position: fixed;
  top: 6.5rem;
  background-color: $white;
  left: 5rem;
  z-index: 999;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  border: 0.1rem solid $purple;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0px 1px 4px rgba($black, 0.15);
  @include bp(md-max) {
    top: 1rem;
    left: 1rem;
    display: none;
  }

  @include bp(lg-min) {
    display: flex;
    .sidenav-visible & {
      &:after {
        @include rotate(180deg);
        left: 0rem !important;
        top: -0.1rem;
      }
    }
  }
  &:after {
    content: '\77';
    font-family: $enterpriseFont;
    color: $purple;
    position: absolute;
    left: 0.1rem;
    right: 0;
    top: 0.1rem;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem;
  }
}

.menu-expand-btn {
  display: flex;
  position: fixed;
  left: 5.6rem;
  top: 5.8rem;
  z-index: 100;
  cursor: pointer;
  @extend .transition;
  .sidenav-submenu-fixed & {
    left: 26.5rem;
    &:after {
      @include rotate(180deg);
      left: -0.2rem !important;
    }
  }
  @include bp(lg-min) {
    display: none;
  }
  @include bp(lg-min) {
    left: -1.6rem;
    .sidenav-visible & {
      left: 5.6rem;
    }
  }
  &:after {
    content: '\77';
    font-family: $enterpriseFont;
    color: $white;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    //@extend .transition;
  }
}

.be-sidenav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: $white;
  // border: 1px solid rgba(222, 214, 241, 0.5);
  box-shadow: 0px 2px 4px rgba($black, 0.15);
  width: 5.6rem;
  text-align: left;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s;

  @include bp(md-max) {
    transform: translate(-24rem);
    width: 24rem;
    .sidenav-visible & {
      left: 0;
      transform: translate(0);
    }
  }
  a {
    transition: none;
  }
  .sidenav-outer {
    padding: 2.9rem 0rem 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: $white;
    // overflow: hidden auto;
    // @include custom-scroll;
  }

  .top-sidenav {
    display: flex;
    flex-direction: column;
    align-items: center;

    @extend .no-bullets;
    // min-height: 12.8rem;
    padding: 0rem;
    background: $white;
    li {
      margin-bottom: 2rem;
      border-bottom: 0.1rem solid $grey2;
      width: 100%;
      padding-bottom: 1.5rem;
      padding-left: 1.7rem;
      padding-right: 0.8rem;

      &:last-child {
        margin-bottom: 0;        
        padding-bottom: 1.5rem;
        padding-left: 1.7rem;
      }
    }
    .site-logo {
      color: $purple1;
      display: inline-flex;
      font-size: 2.1rem;
    }
    .natasha-link {
      display: inline-flex;
      width: 3.5rem;
      color: $black1;
      .nav-text {
        padding-left: 1.6rem !important;
        overflow: hidden;
        animation: fade-in 1s ease-out forwards;
        &[fading-out] {
          animation: fade-out 1s ease-out forwards;
        }
      }
      .natasha-logo {
        transform: translate(-2%, -54%);
      }
    }
    .hamburger-icon {
      display: none;
      @include bp(md-max) {
        display: flex;
        margin: -0.4rem 0 0 1.5rem;
      }
    }
  }
  .notifications-info {
    display: none;
    animation: fade-in 1s ease-out forwards;
    &[fading-out] {
      animation: fade-out 1s ease-out forwards;
    }
  }
  .middle-nav {
    @extend .no-bullets;
    padding: 0rem;
    background: $white;
    > li {
      padding: 1.4rem 1rem 0;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
        margin-top: 1.5rem;
        padding-top: 1rem;
        border-top: 0.1rem solid $grey2;
      }
      // &:hover {
      //   .submenu-wrapper {
      //     display: block;
      //   }
      // }
      > a,
      .middle-nav-link {
        color: $black1;
        font-size: 2.2rem;
        line-height: 0;
        cursor: pointer;
        height: 3.6rem;
        width: 3.7rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.4rem;
        &:hover {
          background-color: $lightPurple !important;
        }
        &.active {
          color: $purple1;
          background-color: rgb(243 235 255 / 50%);
          .be-down-arrow {
            .sel {
              display: inline-block;
            }
            .nrl {
              display: none;
            }
          }
        }
        &.be-buildcard {
          .space-menu-fixed.sidenav-submenu-fixed & {
            color: $purple;
          }
          &:hover {
            background: transparent;
          }
        }
        &.be-building {
          .company-menu-fixed.sidenav-submenu-fixed & {
            color: $purple;
          }
        }
      }
      .submenu-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 5.8rem;
        width: 24rem;
        background: $white;
        box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.15);
        padding: 3.5rem 00;
        z-index: -1;
        overflow: hidden auto;
        @include custom-scroll;
        @include translateX(-27rem);
        @extend .transition;
        @include bp(md-max) {
          transform: translateX(-31rem);
        }
        &.active {
          @include translateX(0rem);
          @include bp(md-max) {
            left: -6.5rem;
          }
          // .sidenav-submenu-fixed & {
          // }
          .submenu-body {
            padding-left: 0;
            .trigger-link.false em {
              font-size: 1.6rem !important;
            }
            .be-automations {
              font-size: 1.6rem !important;
            }
          }
        }
        &:not(.active).default-fixed {
          .sidenav-submenu-fixed & {
            display: block;
          }
        }
        .sidenav-submenu-fixed:not(.sidenav-visible) & {
          @include bp(md-max) {
            opacity: 0 !important;
            visibility: hidden !important;
            transform: translateX(1rem) !important;
          }
        }
        .submenu-titlebar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.8rem 1.5rem;
          margin-bottom: 0.3rem;
          position: relative;
          h6 {
            margin: 0;
            font-weight: 400;
          }
          .search-trigger {
            color: $black1;
            font-size: 1.5rem;
            line-height: 0;
            cursor: pointer;
            &:hover {
              color: $purple;
            }
          }
          .submenu-searchbar {
            @extend .absolute-pos;
            z-index: 1;
            background: $white;
            display: flex;
            align-items: center;
            padding: 0.8rem;
            .search-icon {
              height: 16px;
              width: 16px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              color: $black1;
            }
            .search-input {
              padding: 0 0.8rem;
              width: calc(100% - 4rem);
              border: 0;
              color: $grey4;
              font-size: 1.4rem;
              line-height: 2rem;
              outline: 0;
              box-shadow: none;
              @include placeholder {
                color: $grey4 !important;
                font-size: 1.4rem !important;
                opacity: 1 !important;
              }
            }
            .close-searchbar {
              width: 2.4rem;
              height: 2.4rem;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border: 0.1rem solid rgba(179, 136, 255, 0.3);
              border-radius: 0.6rem;
              font-size: 1rem;
              cursor: pointer;
              @extend .transition;
              &:hover {
                border-width: 0.2rem;
              }
            }
          }
        }
        .submenu-body {
          margin: 0 0rem;
          padding: 0 1rem 2rem 2.2rem;
          // overflow: hidden auto;
          // max-height: calc(100vh - 8rem);
          // @include custom-scroll;
        }
        .custom-accordion {
          border: 0;
          border-radius: 0;
          background-color: transparent;
          .no-result-found {
            font-size: 1.4rem;
            @include line-height(14, 18);
            color: $black1;
            text-align: left;
            padding-left: 0.8rem;
          }
          .MuiAccordion-root {
            border: 0;
            margin-bottom: 0.3rem;
            &:last-child {
              margin-bottom: 0;
            }
            .MuiAccordionSummary-content {
              display: block;
              max-width: 100%;
              font-weight: 500;
              animation: fade-in 1s ease-out forwards;
              &[fading-out] {
                animation: fade-out 1s ease-out forwards;
              }
            }
            .MuiAccordionSummary-root {
              padding: 0;
              margin-left: auto;
              padding-left: 0rem;
              border: 0px solid transparent;
              border-radius: 0rem;
              &::after {
                content: '\4e';
                content: '\6a';
                font-size: 0.9rem;
                position: absolute;
                left: 0.6rem;
                visibility: hidden;
                opacity: 0;
              }
              &.Mui-expanded {
                &::after {
                  visibility: visible;
                  opacity: 1;
                  @include rotate(90deg);
                }
              }
              .trigger-link {
                border-color: transparent;
                background-color: transparent;
              }
              &:hover,
              &.active {
                border: 0px solid #e8e8e8;
                background-color: #f3ebff;
                &.expand-icon {
                  &::after {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }
            }
            .MuiAccordionDetails-root {
              padding: 1.2rem 1rem 0.2rem 2.6rem;
            }
          }
          .sub-project-list {
            @extend .no-bullets;
            text-align: left;
            margin-top: -1rem;
            li {
              margin-bottom: 0rem;
              &:last-child {
                margin-bottom: 0;
                margin-top: 0;
                padding-top: 0;
              }
              a {
                font-size: 1.2rem;
                @include line-height(12, 18);
                @extend .truncated-text;
                color: $black1;
                display: block;
                height: auto;
                padding-top: 0.6rem;
                padding-bottom: 0.6rem;
                &:hover {
                  color: $purple2;
                }
                .be-folder {
                  color: $purple2;
                  margin-right: 0.6rem;
                  font-size: 1.4rem;
                  line-height: 1;
                  position: relative;
                  top: 0.2rem;
                }
              }
            }
          }
        }

        .trigger-link {
          padding: 0.8rem 0.6rem 0.8rem 1.7rem;
          display: flex;
          justify-content: start;
          align-items: center;
          font-size: 1.2rem;
          @include line-height(12, 16);
          color: $black1;
          // border: 0.1rem solid transparent;
          border-radius: 0rem;
          text-align: left;
          height: 3.6rem;
          width: 100%;
          animation: fade-in 1s ease-out forwards;
          &[fading-out] {
            animation: fade-out 1s ease-out forwards;
          }
          &.active {
            border-color: $grey3 !important;
            background-color: $lightPurple1 !important;
            span {
              color: $purple !important;
            }
          }
          &:hover {
            background-color: $lightPurple;
            border-color: $grey3;
          }

          .icon {
            height: 1.8rem;
            width: 1.8rem;
            border-radius: 0.3rem;
            background-color: $purple1;
            color: $white;
            font-size: 1.2rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            &.is-black {
              color: $black1;
              background-color: transparent;
            }
          }
          span {
            font-weight: 400;
            font-size: 1.4rem;
            color: $black1;
            // width: calc(100% - 2.3rem);
            padding-left: 1.3rem;
            width: 86% !important;
            white-space: nowrap;
            //@extend .truncated-text;
            animation: fade-in 1s ease-out forwards;
            &[fading-out] {
              animation: fade-out 1s ease-out forwards;
            }
            img {
              position: relative;
              top: 0.2rem;
              left: 0;
            }
            &.truncated-text {
              @extend .truncated-text;
            }
          }
        }

        .submenu-list {
          @extend .no-bullets;
          li {
            margin-bottom: 0.6rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
        }
        &:hover {
          &::-webkit-scrollbar-thumb {
            background: #afb2c0;
          }
        }
      }
    }
  }

  .bottom-sidenav {
    background: #fff;
    padding: 0.8rem 1rem 0 1.2rem;
    text-align: left;
    border-top: 0.1rem solid $grey2;
    @extend .no-bullets;
    li {
      margin-bottom: 1.5rem;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: $black1;
        font-size: 1.9rem;
        line-height: 0;
        position: relative;
        width: 3.2rem;
        height: 3.2rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.5rem;
        &:hover {
          background-color: $lightPurple;
        }
        &.user-menu {
          width: 3.2rem;
          height: 3.2rem;
          overflow: hidden;
          border-radius: 50%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background: $lightPurple;
          font-size: 1.2rem;
          font-weight: 500;
          color: $purple2;
          img {
            height: 100%;
            width: 100%;
            @extend .cover-fit;
          }
          > span {
            text-transform: capitalize;
          }
        }
        .online-badge {
          display: inline-block;
          height: 1.1rem;
          width: 1.1rem;
          border-radius: 50%;
          background: $purple1;
          border: 0.2rem solid $white;
          position: absolute;
          left: 2rem;
          bottom: 0.4rem;
        }
        &.be-info {
          font-size: 1.7rem;
          color: #5e5f62;
          position: relative;
          top: 0.5rem;
        }
      }
      .user-menu-outer {
        position: fixed;
        left: 5.8rem;
        bottom: 1.4rem;
        width: 21.6rem;
        background: $white;
        box-shadow: 0 1.2rem 2.4rem rgba($black, 0.15);
        border-radius: 0.4rem;
        padding: 2rem;
        text-align: left;
        z-index: 100;
        @extend .transition;
        @include translateY(1rem);
        opacity: 0;
        visibility: hidden;
        &.open {
          @include translateY(0);
          opacity: 1;
          visibility: visible;
        }
        .close-menu {
          position: absolute;
          right: 0.9rem;
          top: 1rem;
          cursor: pointer;
          font-size: 1.2rem;
        }
        .user-info {
          display: flex;
          align-items: center;
          margin-bottom: 1.6rem;
          .dp {
            height: 3.2rem;
            width: 3.2rem;
            border-radius: 50%;
            overflow: hidden;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: $lightPurple;
            font-size: 1.2rem;
            font-weight: 500;
            color: $purple2;
            font-style: normal;
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
          .name {
            width: calc(100% - 3.2rem);
            padding-left: 0.8rem;
            font-size: 1.4rem;
            line-height: 2rem;
            letter-spacing: 0.01em;
            @extend .truncated-text;
          }
        }
        .links {
          @extend .no-bullets;
          li {
            margin-bottom: 0.8rem;
            &:last-child {
              margin-bottom: 0;
            }
            a {
              font-size: 1.4rem;
              line-height: 2rem;
              letter-spacing: 0.01em;
              font-weight: 500;
              display: block;
              width: 100%;
              height: auto;
              background: transparent;
              &:hover {
                color: $purple1;
              }
            }
          }
        }
      }
      .d-flex {
        cursor: pointer;
      }
    }
  }
  .top-sidenav,
  .middle-nav {
    .nav-text {
      display: none;
      transition: all 0.3s;
    }
  }
  .builder-logo {
    display: none;
    transition: all 0.3s;
  }
}

// No data wrapper
.no-data-wrapper {  
  .thumb {
    margin: 0 auto;
  }
  h4 {
    margin: 1.6rem 0 0.4rem;
    color: $black1;
    font-size: 1.6rem !important;
    font-weight: 500;
    @include line-height(16, 24);
    margin-bottom: 0 !important;
  }
  p {
    color: $trueGrey;
    font-size: 14px !important;
    @include line-height(14, 20);
    letter-spacing: 0.014rem;
  }
}

.company-dropdown {
  transition: none !important;
}
.sidenav-visible {
  .nav-tooltip {
    opacity: 0 !important;
  }

  .notifications-panel {
    left: 24.03rem;
    border-left: 1px solid $grey2;
    @include bp(sm-max) {
      left: 0;
      width: 100vw;
    }
  }
  &.space-menu-fixed {
    .middle-nav li .MuiAccordionSummary-root {
      padding-left: 0 !important;
    }
    .company-dropdown {
      padding-left: 0 !important;
    }
  }
  .no-data-wrapper.no-border.sm-pad {
    position: static;
    left: 62px;
    margin-left: -1.3rem;
  }
  .submenu-titlebar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem;
    margin-bottom: 0.3rem;
    position: relative;
    h6 {
      margin: 0;
      font-weight: 400;
    }
    .search-trigger {
      color: $black1;
      font-size: 1.5rem;
      line-height: 0;
      cursor: pointer;
      &:hover {
        color: $purple;
      }
    }
    .submenu-searchbar {
      @extend .absolute-pos;
      z-index: 1;
      background: $white;
      display: flex;
      align-items: center;
      padding: 0.8rem;
      left: -22px;
      width: 128%;
      border-radius: 3px;
      .search-icon {
        height: 16px;
        width: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $black1;
        font-size: 1.6rem;
      }
      .search-input {
        padding: 0 0.8rem;
        width: calc(100% - 4rem);
        border: 0;
        color: $grey4;
        font-size: 1.4rem;
        line-height: 2rem;
        outline: 0;
        box-shadow: none;
        @include placeholder {
          color: $grey4 !important;
          font-size: 1.4rem !important;
          opacity: 1 !important;
        }
      }
      .close-searchbar {
        width: 2.4rem;
        height: 2.4rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 0.1rem solid rgba(179, 136, 255, 0.3);
        border-radius: 0.6rem;
        font-size: 1rem;
        cursor: pointer;
        @extend .transition;
        &:hover {
          border-width: 0.2rem;
        }
      }
    }
  }
  .be-sidenav {
    width: 24rem;
    .middle-nav {
      margin-top: 0.5rem;
      li {
        .company-dropdown {
          transform: none;
          position: static;
          z-index: 1;
          padding: 0;
          transition: none !important;
          box-shadow: none;
          margin-top: 0.5rem;

          .submenu-titlebar {
            display: none;
          }
          .submenu-body {
            padding: 0 1rem 2rem 2.2rem !important;
            margin: 0;
          }
          &.open-submenu {
            display: none;
            width: 100%;
            .trigger-link {
              padding-left: 1rem !important;
            }
            ul {
              li {
                padding-right: 0;
                margin-top: 0;
                padding-top: 0;
                padding-left: 0;
              }
            }
          }
        }
        .be-down-arrow {
          // transform: rotate(-90deg);
          position: relative;
          top: 0;
          right: 0.4rem;
          &:before {
            display: none;
          }
          .sel {
            display: none;
          }
        }

        > a,
        .middle-nav-link {
          width: auto;
        }
        a {
          width: 22.4rem;
          justify-content: start;
        }
      }
      .trigger-link {
        padding-left: 1rem !important;
      }
    }
    .sidenav-outer {
      padding-top: 2rem;
      @include bp(md-max) {
        padding-top: 1rem;
      }
    }
    .bottom-sidenav {
      padding-left: 0.8rem;
      position: sticky;
      bottom: 2rem;
      z-index: 2;
      li {
        a {
          width: 100%;
          justify-content: start;
          .notifications-info {
            font-size: 16px;
            padding-left: 1rem;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .badge {
              background-color: $purple;
              width: 2.4rem;
              height: 2.4rem;
              font-size: 1.2rem;
              color: $white;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              border-radius: 6rem;
              margin-right: 2rem;
              position: absolute;
              right: 0;
            }
          }
          .online-badge {
            display: none;
          }
          // &:hover{
          //   background-color: transparent;
          // }
          .be-notification {
            width: 3.5rem;
            font-size: 2.2rem;
            text-align: center;
          }
        }
        .notifications-info {
          display: inline-block;
          flex-wrap: wrap;
          width: 80%;
          padding-left: 1rem;
          line-height: 19px;
          @extend .transition;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 3.2rem;
          span {
            font-size: 1rem;
            color: $grey11;
            display: block;
            word-break: break-word;
            line-height: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.user-name {
              font-size: 1.6rem;
              font-weight: 400;
              color: $black1;
            }
          }
        }
        &:last-child {
          a {
            width: 3.2rem;
            justify-content: center;
            padding-left: 0.7rem;
          }
        }
        &:first-child {
          a {
            padding: 1rem 0rem 1rem 0.7rem;
            height: auto;
          }
        }
      }
    }
  }

  .bottom-sidenav li .user-menu-outer {
    left: 1rem;
  }
  .top-sidenav {
    li {
      margin-bottom: 0 !important;
    }
  }

  .middle-nav {
    overflow: hidden auto;
    @include custom-scroll;
    max-height: calc(94vh - 14.6rem);
        
    @include bp(xlg-max) {
      max-height: calc(94vh - 16rem);
    }
    @include bp(smd-max) {
      max-height: calc(94vh - 15rem);
    }

    li {
      &:last-child {
        padding-top: 0.5rem;
        margin-top: 0.5rem;
      }
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #afb2c0;
      }
    }
  }

  .custom-accordion {
    margin-top: 0rem;
  }
  .natasha-link {
    width: 100% !important;
    border-radius: 0.4rem;
    padding: 0.5rem;
    &:hover {
      background-color: $lightPurple;
    }
    .natasha-logo {
      transform: none !important;
    }
  }

  // TODO : when Natasha icon in nav will be enabled 
  // it will again with padding top and bottom [padding: 0.5rem 0.8rem;]
  // for now it's adjusted with padding top and bottom with 0
  .top-sidenav li:last-child {
    padding: 0 0.8rem;    
  }

  .middle-nav,
  .top-sidenav {
    text-align: left;
    .nav-text {
      font-size: 1.6rem;
      padding-left: 1rem;
      display: inline-flex;
      width: 170px;
      justify-content: space-between;
      cursor: pointer;
      align-items: center;
      animation: fade-in 1s ease-out forwards;
      &[fading-out] {
        animation: fade-out 1s ease-out forwards;
      }
    }
    li {
      padding: 0 0.8rem;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0.5rem;
      padding-right: 0;
      a {
        padding: 1rem 1rem;
        height: 5rem;
        transition: none;

        &.hub-sticky {
          position: sticky;
          top: 0;
          z-index: 2;
          background-color: $white;
        }
      }
    }
  }
  .build-hub {
    display: flex;
    justify-content: space-between;
    width: 84%;
    align-items: center;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    .submenu-searchbar {
      display: flex;
    }
  }
  .be-builder-logo-b {
    display: none !important;
  }
  .builder-logo {
    display: inline-flex;
    max-width: 15.5rem;
    animation: fade-in 1s ease-out forwards;
    &[fading-out] {
      animation: fade-out 1s ease-out forwards;
    }
  }
  .mobile-menu-icon {
    left: 23rem;
    top: 6.3rem;
  }
  .main-content {
    padding-left: 23.6rem;
    transition: all 0.3s;
  }
}

.space-menu-fixed {
  .default-fixed {
    .submenu-titlebar {
      padding-left: 1.8rem !important;
    }
  }
  .open-submenu {
    display: block !important;
    cursor: pointer;
    padding: 10px;
    .icon {
      font-size: 1.6rem !important;
    }
    li {
      a {
        &.active {
          background-color: $lightPurple1 !important;
          border: 0 !important;

          span {
            color: $purple !important;
          }
          .icon {
            color: $purple !important;
          }
        }
      }
    }
  }

  .be-down-arrow {
    transform: rotate(90deg) !important;
    position: relative;
    top: 0rem !important;
  }
  .custom-accordion {
    .trigger-link {
      padding-left: 1.7rem !important;
    }
  }
}

.build-hub {
  .submenu-searchbar {
    display: none;
  }
}
.company-menu-fixed {
  .open-submenu {
    ul {
      li {
        a {
          padding: 0.8rem 1.6rem !important;
          em {
            font-size: 1.6rem !important;
          }
        }
        .trigger-link {
          padding-left: 1.6rem !important;
          em {
            font-size: 1.6rem !important;
          }
          &.active {
            background-color: $lightPurple1 !important;
            span,
            em {
              color: $purple !important;
            }
          }
        }
      }
    }
  }
}

.company-menu-fixed {
  .be-sidenav {
    .middle-nav {
      .submenu-wrapper {
        .submenu-body {
          padding: 0 0rem 2rem 0 !important;
        }
      }
    }
  }
}

.default-fixed {
  .submenu-body {
    padding: 0 0rem 2rem 0rem !important;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
