// Notification panel
.notifications-panel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 5.8rem;
  width: 46.2rem;
  padding: 1.2rem 0 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  transform: translateX(1rem);
  z-index: 50;
  background: $white;
  @extend .transition;
  box-shadow: 8px 4px 16px 0px rgba(0, 0, 0, 0.15);
  &.active {
    opacity: 1;
    visibility: visible;
    @include translateX(0);
  }
  @include bp(md-max) {
    left: 0rem;
  }
  .sidenav-visible & {
    @include bp(md-max) {
      left: 5.6rem;
    }
  }
  @include bp(sm-max) {
    width: 100%;
  }
  .sidenav-submenu-fixed & {
    left: 26.5rem;
  }
  .head-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.6rem;
    .title {
      font-size: 1.4rem;
      @include line-height(14, 20);
      color: $black1;
      font-weight: 500;
      display: inline-flex;
      align-items: center;
      em,
      img {
        margin-right: 0.8rem;
      }
    }
  }
  .notification-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.2rem 1.6rem 0;

    .left {
      display: flex;
      align-items: center;
      .MuiTabs-root {
        min-height: 3.2rem;
        .MuiButtonBase-root {
          padding: 0.4rem 0.8rem;
          min-height: 3.2rem;
          min-width: auto;
          font-size: 1.4rem;
          font-weight: 400;
          @include line-height(14, 24);
          text-transform: capitalize;
          &:first-child {
            margin-right: 0.8rem;
          }
          &.Mui-selected {
            font-weight: 500;
          }
          &:hover:not(.Mui-selected) {
            color: $purple1;
            &::after {
              left: 0;
              right: 0;
              width: 100%;
            }
          }
          &:not(.Mui-selected) {
            &::after {
              content: '';
              position: absolute;
              left: 50%;
              right: 50%;
              bottom: 0;
              width: 0;
              height: 0.2rem;
              background-color: $purple1;
              @extend .transition;
            }
          }
        }
      }
    }
    .right {
      margin-right: -0.8rem;
      @include bp(sm-max) {
        margin-right: 0;
      }
      .mark-all-read {
        color: $purple1;
        font-size: 1.4rem;
        font-weight: 400;
        @include line-height(14, 24);
        letter-spacing: 0.05rem;
        padding: 0.4rem 0.8rem;
        border-radius: 0.5rem;
        display: inline-flex;
        cursor: pointer;
        > span {
          text-decoration: underline;
          transition: all ease-in 0.3s;
        }
        em {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.8rem;
        }
        &:hover {
          > span {
            text-decoration: none;
          }
        }
      }
    }
    @include bp(sm-max) {
      padding-bottom: 0.4rem;
    }
  }
  .notifications-body {
    position: relative;
    .MuiTabPanel-root {
      padding: 0;
      height: 100%;
      max-height: calc(100vh - 7.8rem);
      overflow-y: auto;
      @include custom-scroll;
    }
    .notification-row {
      border-bottom: 1px solid $grey2;
      padding: 0 1.2rem 0 0.6rem;
      &:first-child {
        border-top: 1px solid $grey2;
      }
      &.has-greyBG,
      &:hover {
        background: $lightPurple1;
        .btn-white {
          border: 1px solid $purple3 !important;
          background: $purple3 !important;
          color: $white !important;
        }
      }
      @include bp(smd-max) {
        display: flex;
        flex-flow: column;
      }
      .user-link {
        padding: 1.6rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        line-height: 1.4285714286;
        letter-spacing: 0.01em;
        font-weight: 500;
        position: relative;
        padding-right: 30px;
        .left-content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          padding: 0 0 0 1.2rem;
          .blue-dot {
            width: 0.8rem;
            height: 0.8rem;
            background: $purple1;
            margin: 0 1rem 0 0;
            border: 1px solid $white;
            border-radius: 100%;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -0.5rem;
          }
        }
        //.right-content { }
        .menu-trigger {
          position: absolute;
          right: 0;
          top: 2.6rem;
          cursor: pointer;
          @include translateY(-50%);
          line-height: 0;
          display: inline-block;
          .trigger-icon {
            display: inline-block;
            line-height: 0;
            color: $grey4;
            font-size: 24px;
            @include rotate(90deg);
          }
        }

        .initials,
        .user-avatar {
          @include user-avatar(
            1.4rem,
            500,
            $white,
            transparent,
            3.2rem,
            3.2rem,
            3.2rem
          );
          cursor: pointer;
          img {
            border-radius: 50%;
          }
        }
        .initials {
          border: 2px solid $white;
          outline: none;
          background: $purple2;
        }
        .user-detail {
          padding-left: 1.2rem;
          letter-spacing: 0.014rem;
          color: $black;
          font-weight: normal;
          .basic-info {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            flex-flow: column;
            .user-name {
              padding-left: 0;
              color: $purple1;
              cursor: pointer;
            }
            .description-text {
              max-width: 31.7rem;
              color: $black1;
              font-size: 1.4rem;
              font-weight: 400;
              @include line-height(14, 20);
              letter-spacing: 0.014rem;
              text-align: initial;
              word-wrap: break-word;
              a,
              strong {
                font-size: inherit;
                font-weight: 500;
                color: inherit;
                cursor: pointer;
              }
            }
          }
          .btn-wrap {
            margin: 0.8rem 0 0;
            .btn-white {
              background-color: $white;
              border: 1px solid rgba(179, 136, 255, 0.3) !important;
              outline: 1px solid transparent;
              height: 3.6rem;
              padding: 1.6rem 2.4rem;
              color: $black1;
              font-size: 1.4rem;
              font-weight: 500;
              @include line-height(14, 20);
              letter-spacing: 0.014rem;
              box-sizing: border-box;
              &.btn-white {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
        .time {
          color: $grey11;
          position: relative;
          padding-left: 10px;
          font-size: 1.2rem;
          font-weight: 400;
          max-width: 8rem;
          display: inline-block;
          @extend .truncated-text;
          @include line-height(12, 18);
        }
      }
    }
  }
  .close-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1.6rem;
    width: 1.6rem;
    &::before {
      color: $black1;
      font-size: 0.9rem;
      font-weight: bold !important;
    }
    &:hover {
      &::before {
        color: $black;
      }
    }
    @include bp(sm-max) {
      display: flex;
    }
  }
}

.no-data-wrapper {
  &.notifications {
    border: 0;
    transform: translate(-50%, 150%);
    position: absolute;
    top: 0;
    left: 50%;
    .thumb {
      margin-bottom: 1.6rem;
    }
    h4 {
      @include line-height(16, 24);
    }
  }
}
